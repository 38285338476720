import React, { useState } from 'react';
import './Project.css';
import { useNavigate } from 'react-router-dom';

function Project() {
    const [selectedCategory, setSelectedCategory] = useState('All');
    const navigate=useNavigate();
    const projects = [
        {
          id: 1,
          category:'ERP Modules',
          span: 'ERP Modules',
          name: 'ERP System',
          image: 'static/media/images/ERP-3.jpg',
        },
        {
          id: 2,
          category:'Mobile App',
          span: 'Mobile App',
          name: 'Principal Mobile App',
          image: 'static/media/images/principal-app.jpg',
        },
        {
          id: 3,
          category:'Mobile App',
          span: 'Mobile App',
          name: 'Teacher Mobile App',
          image: 'static/media/images/Mobile-app1.jpg',
        },
        {
            id: 4,
            category:'Mobile App',
            span: 'Mobile App',
            name: 'Parent / Student Mobile App',
            image: 'static/media/images/Mobile-app2.jpg',
        },{
            id: 5,
            category:'Launch Setup',
            span:'Launch Setup',
            name: 'Found a tech',
            image: 'static/media/images/project-1.jpg',
        }
        
        // Add more project objects as needed
      ];

    // Function to handle click on category buttons
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    // Filter projects based on the selected category
    const filteredProjects = selectedCategory === 'All' ? projects : projects.filter(project => project.category === selectedCategory);

  return (
    <div>
        <div className='project-main'>
            <div className='project-container'>
                <div className='title-project'>
                    <div className='inner-title-project'>
                        <span>Care Study</span>
                        <h2>Project</h2>
                        <p>Discover excellence and innovation in our projects, tailored solutions that exceed expectations. From Enterprise Software Solutions to rigorous Quality Assurance, our work guarantees reliability and performance, paving the way for your success.</p>
                    </div>
                </div>
                <div className='row-project'>
                    <div className='col-project'>
                        <ul className='project-ul'>
                            {/* Add click handlers to category buttons */}
                            <li onClick={() => handleCategoryClick('All')}>All</li>
                            <li onClick={() => handleCategoryClick('Launch Setup')}>Launch Setup</li>
                            <li onClick={() => handleCategoryClick('Mobile App')}>Mobile App</li>
                            <li onClick={() => handleCategoryClick('ERP Modules')}>ERP Modules</li>
                            {/* <li onClick={() => handleCategoryClick('Graphic Design')}>Graphic Design</li> */}
                        </ul>
                    </div>
                </div>

                <div className='row-project2'>
                    {/* Map over filtered projects and render a card for each project */}
                    {filteredProjects.map((project) => (
                        <div key={project.id} className='col-project2' onClick={() => navigate('/project-details')}> 
                            <div className='item-img'>
                                <a href='/project-details'>
                                    <img src={project.image} className='pro-img' alt={project.name} />
                                </a>
                            </div>
                            <div className='item-inner-cut' onClick={() => navigate('/project-details')}>
                                <span>{project.span}</span>
                                <h4>{project.name}</h4>
                                {/* <div className="read-more-bttn">
                                    <a href="/project-details" style={{color:"white"}}>View Details</a>
                                </div> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Project;
