import React from 'react';
import Navbar1 from '../Components/Navbar1';
import Footer1 from '../Components/Footer1';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './ContactPg.css';
function ContactPg() {
  return (
    <div>
        <Navbar1/>
      
        <div className='aboutpg-main'>
            <div className='pg-container'>
                <div className='pg-row'>
                    <div className='pg-col'>
                        <div className='pg-wrapper'>
                            <div className='pg-wrapper-cnt'>
                                <h1>Contact Us</h1>

                                <div className='about-img-wrapper'>
                                    <img src='static/media/images/about-pg-img2.jpg'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="line-wrap">
        <div className="line-item"></div>
        <div className="line-item"></div>
        <div className="line-item"></div>
        <div className="line-item"></div>
        <div className="line-item"></div>

</div>
        <div className='contact-pg-main'>
            <div className='contact-pg-con'>
                <div className='contact-pg-row'>
                    <div className='contact-pg-col1'>
                        <div className='contact-left'>
                            <div className='sec-layout-title-cont'>
                                <span>Get in touch</span>
                                <h2>Contact us if you have more questions.</h2>
                            </div>
                            <div className='info'>
                                <div className='single-info'>
                                    <div className='info-icon'>
                                        <FontAwesomeIcon icon={faLocationDot} className='i-info'/>
                                    </div>
                                    <div className='info-text'>
                                        <h3>Location</h3>
                                        <p>Oxbow street 
Micklenham 3064
Victoria, Australia</p>
                                    </div>
                                </div>

                                {/* <div className='single-info'>
                                    <div className='info-icon'>
                                        <FontAwesomeIcon icon={faPhone} className='i-info'/>
                                    </div>
                                    <div className='info-text'>
                                        <h3>Phone</h3>
                                        <p>+92 32345432</p>
                                        <p>+92 32345432</p>
                                    </div>
                                </div> */}

                                <div className='single-info'>
                                    <div className='info-icon'>
                                        <FontAwesomeIcon icon={faEnvelope} className='i-info'/>
                                    </div>
                                    <div className='info-text'>
                                        <h3>Email</h3>
                                        <p>info@techlogix.au</p>
                                        {/* <p>+92 32345432</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='contact-pg-col1'>
                        <div className='img-contact'>
                            <img src='static/media/images/call-center.png'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <Footer1/>
    </div>
  )
}

export default ContactPg