import React, {useEffect} from 'react'
import Navbar1 from '../Components/Navbar1'
import Footer1 from '../Components/Footer1'
import './ProjectDetails.css'
function ProjectDetails() {
    useEffect(() => {
        function isInViewport(element) {
            const rect = element.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        function handleScroll() {
            const projectDetailsList = document.querySelectorAll('.project-details');
            projectDetailsList.forEach((projectDetails) => {
                if (isInViewport(projectDetails)) {
                    projectDetails.classList.add('animate');
                }
            });
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
  return (
    <div>
        <Navbar1/>
        <div className='aboutpg-main'>
            <div className='pg-container'>
                <div className='pg-row'>
                    <div className='pg-col'>
                        <div className='pg-wrapper'>
                            <div className='pg-wrapper-cnt'>
                                <h1>Project Details</h1>

                                <div className='about-img-wrapper'>
                                    <img src='static/media/images/about-pg-img2.jpg'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='project-details-main'>
        <div className='project-details'>
                <span>01</span>
                <div className='project-details-inner'>
                    <div className='project-details-img'>
                        <img src='static/media/images/project-6.jpg' className='project-details-imginner'/>
                    </div>
                    <div className='project-details-content'>
                        <h3>Launch Setup</h3>
                        <p>Welcome to our revolutionary project launch setup, the cornerstone for building virtual foundations of educational institutes. With meticulous planning and cutting-edge technology, we lay the groundwork for seamless daily operations. From enrollment to setting up classes, our platform ensures a smooth transition to the digital realm, empowering educational institutions to thrive in an ever-evolving landscape.</p>
                    </div>
                </div>
            </div>
            <div className='project-details'>
                <span>02</span>
                <div className='project-details-inner'>
                    <div className='project-details-img'>
                        <img src='static/media/images/ERP-1.jpg' className='project-details-imginner'/>
                    </div>
                    <div className='project-details-content'>
                        <h3>ERP Modules</h3>
                        <p>Our project – ERP Modules redefine efficiency in educational institutions, offering a comprehensive suite including Attendance, Fee Management, Accounts, Student Records, and HR functionalities. Revolutionizing daily operations, our modules streamline tasks such as attendance tracking, fee generation and payment processing, staff salary management, and student and staff data administration. Experience seamless integration and transformative efficiency with our ERP solutions, shaping the future of educational management.</p>
                    </div>
                </div>
            </div>

            <div className='project-details'>
                <span>03</span>
                <div className='project-details-inner'>
                    <div className='project-details-img'>
                        <img src='static/media/images/principal-app.jpg' className='project-details-imginner'/>
                    </div>
                    <div className='project-details-content'>
                        <h3>Principal Mobile App</h3>
                        <p>Continuing our efforts to digitize education we have developed Principal Mobile App redefines administrative capabilities, empowering school owners and principals with unparalleled efficiency and control. Seamlessly bridging the gap between leadership and operations, this transformative tool offers real-time insights, streamlined communication, and enhanced decision-making. Experience the future of educational management at your fingertips, revolutionizing how schools are led and operated.</p>
                    </div>
                </div>
            </div>

            <div className='project-details'>
                <span>04</span>
                <div className='project-details-inner'>
                    <div className='project-details-img'>
                        <img src='static/media/images/Mobile-app1.jpg' className='project-details-imginner'/>
                    </div>
                    <div className='project-details-content'>
                        <h3>Teachers Mobile App</h3>
                        <p>Teacher Mobile App developed by Tech Logix revolutionizes classroom management, empowering educators with enhanced capabilities and efficiency. Designed to streamline daily tasks and communication, this transformative tool offers real-time access to attendance records, lesson plans, student progress reports, and more. With intuitive features tailored for educators, our app enhances teaching effectiveness and fosters a collaborative learning environment. Embrace the future of education in the palm of your hand, empowering teachers to inspire and excel.</p>
                    </div>
                </div>
            </div>

            <div className='project-details'>
                <span>05</span>
                <div className='project-details-inner'>
                    <div className='project-details-img'>
                        <img src='static/media/images/Mobile-app2.jpg' className='project-details-imginner'/>
                    </div>
                    <div className='project-details-content'>
                        <h3>Parents / Students Mobile App Mobile App</h3>
                        <p>Our Parents / Students Mobile App redefines the educational experience, putting the power of learning in the palm of your hand. Seamlessly connecting parents, students, and educators, this transformative tool offers real-time access to attendance records, grades, assignments, and important announcements. With intuitive features tailored for families, our app enhances communication, involvement, and engagement in your child's education journey. Embrace the future of learning and stay connected every step of the way.</p>
                    </div>
                </div>
            </div>

            
        </div>
        <Footer1/>
    </div>
  )
}

export default ProjectDetails