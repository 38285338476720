import React from "react";
import "./About.css";
function About() {
  return (
    <div>
      {/* <section className='about-section'>
            <div className='about-container'>
                <div className='about-row'>
                    <div className='about-col-1'>
                        <div className='about-sec-title'>
                            <span>Get To Know</span>
                            <h2>About Us</h2>
                        </div>
                        <div className='about-left2'>
                            <h3>We do design, code & develop Software finally launch.</h3>
                            <p>Integer purus odio, placerat nec rhoncus in, ullamcorper nec dolor. Class onlin aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos only himenaeos. Praesent nec neque at dolor venenatis consectetur eu quis ex. the Donec lacinia placerat felis non aliquam.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

{/* Mobile */}
<div className="about-mob">
<div className="col2-about">
           <img src="static/media/images/banner1.jpg" alt="About company"/>
           <img src="static/media/images/banner2.jpg" className="img2-banner" alt="About company"/>
        </div>
        <div className="col1-about">
        <span>Get To Know</span>
          <h2>About Us</h2>
          <h3>Elevating enterprises with seamless solutions: from flawless code to actionable insights.</h3>
          <p>At Tech Logix, we merge innovation and excellence to empower businesses with cutting-edge technology solutions. Our relentless commitment to quality and customer satisfaction ensures success every step of the way. From bespoke software solutions to data-driven insights, let's shape the future of technology together.</p>
          <div className="company-since">
            <div className="company-logo-abt">
              <img src="static/media/images/logo-white.png" className="logo-abt"/>
              {/* <strong>#1</strong> */}
            </div>
            <h4>Best Creative IT Agency and Solutions
            </h4>
            <p className="p-since" style={{color:'#75dab4'}}>Since 2024</p>
            
            
          </div>
        </div>
</div>



{/* Laptop */}
      <div className="about-section">
        <div className="col1-about">
          <span>Get To Know</span>
          <h2>About Us</h2>
          <h3>Elevating enterprises with seamless solutions: from flawless code to actionable insights.</h3>
          <p style={{lineHeight:'1.4'}}>At Tech Logix, we merge innovation and excellence to empower businesses with cutting-edge technology solutions. Our relentless commitment to quality and customer satisfaction ensures success every step of the way. From bespoke software solutions to data-driven insights, let's shape the future of technology together.</p>
          <div className="company-since">
            <div className="company-logo-abt">
              <img src="static/media/images/logo-white.png" className="logo-abt" />
              {/* <strong>#1</strong> */}
            </div>
            <h4>Best Creative IT Agency and Solutions
            </h4>
            <p className="p-since" style={{color:'#75dab4'}}>Since 2024</p>
            
            
          </div>
        </div>

        <div className="col2-about">
            <div className="about-right">
                <div className="banner1">
                    <img src="static/media/images/banner1.jpg"/>
                </div>
                <div className="banner2">
                    <img src="static/media/images/banner2.jpg"/>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default About;
