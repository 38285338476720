import React from 'react'
import './Services.css';
import Navbar1 from '../Components/Navbar1';
import Footer1 from '../Components/Footer1';

function ServicesPg() {
  return (
    <div>
        <Navbar1/>
        <div className='aboutpg-main'>
            <div className='pg-container'>
                <div className='pg-row'>
                    <div className='pg-col'>
                        <div className='pg-wrapper'>
                            <div className='pg-wrapper-cnt'>
                                <h1>Services</h1>

                                <div className='about-img-wrapper'>
                                    <img src='static/media/images/about-pg-img2.jpg'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='services-pg-main'>
            <div className='services-pg-con' >
            <div className="title-services" style={{maxWidth: '1000px', textAlign: "justify"}}>
            <div className="sec-title" >
              <span className="span-service">Our Solutions</span>
              <h2 className="h2-service">Services</h2>
              <p>
              Welcome to TECH LOGIX – our innovative software house – where cutting-edge technology meets unparalleled expertise. We specialize in crafting bespoke Enterprise Software Solutions tailored to elevate your business to new heights. With meticulous Quality Assurance and Testing, we ensure flawless performance every step of the way. Our commitment doesn't end there – our dedicated team provides comprehensive Maintenance and Support, ensuring your software remains optimized and up to date. Harnessing the power of Data Analytics and Business Intelligence, we empower you to make informed decisions that drive success. Join us in revolutionizing your digital landscape. At our software house, we offer the following comprehensive suite of services designed to propel your business forward. 
              </p>
            </div>
          </div>

          <div className='services-pg-row'>
            <div className='col-service-pg'>
                <div className='col-service-pg-inner'>
                    <span>01</span>
                    <div className='icon-ser-pg'>
                        <img src='static/media/images/webicon.png'/>
                    </div>
                    <h4>Enterprise Software Solutions</h4>
                    <p>Building scalable and robust software solutions for enterprise-level organizations to streamline operations and improve efficiency.</p>
                    {/* <div className="read-more-bttn">
                      <a href="/services-details">Read More</a>
                    </div> */}
                </div>
            </div>

            <div className='col-service-pg'>
                <div className='col-service-pg-inner'>
                    <span>02</span>
                    <div className='icon-ser-pg'>
                        <img src='static/media/images/ui.png'/>
                    </div>
                    <h4>Data Analytics and Business Intelligence</h4>
                    <p>Leveraging data to provide insights, analytics, and reporting tools to support informed decision-making.</p>
                    {/* <div className="read-more-bttn">
                      <a href="/services-details">Read More</a>
                    </div> */}
                </div>
            </div>

            <div className='col-service-pg'>
                <div className='col-service-pg-inner'>
                    <span>03</span>
                    <div className='icon-ser-pg'>
                        <img src='static/media/images/webicon.png'/>
                    </div>
                    <h4>Cybersecurity Services</h4>
                    <p>Assessing, implementing, and managing cybersecurity measures to protect software and data from cyber threats.</p>
                    {/* <div className="read-more-bttn">
                      <a href="/services-details">Read More</a>
                    </div> */}
                </div>
            </div>

            <div className='col-service-pg'>
                <div className='col-service-pg-inner'>
                    <span>04</span>
                    <div className='icon-ser-pg'>
                        <img src='static/media/images/ui.png'/>
                    </div>
                    <h4>Consulting Services</h4>
                    <p>Offering expert advice and consultancy on technology strategy, digital transformation, and software architecture.</p>
                    {/* <div className="read-more-bttn">
                      <a href="/services-details">Read More</a>
                    </div> */}
                </div>
            </div>

            <div className='col-service-pg'>
                <div className='col-service-pg-inner'>
                    <span>05</span>
                    <div className='icon-ser-pg'>
                        <img src='static/media/images/softwaredev.png'/>
                    </div>
                    <h4>Cloud Solutions</h4>
                    <p>Development and implementation of cloud-based solutions, including migration, deployment, and optimization.</p>
                    {/* <div className="read-more-bttn">
                      <a href="/services-details">Read More</a>
                    </div> */}
                </div>
            </div>

            <div className='col-service-pg'>
                <div className='col-service-pg-inner'>
                    <span>06</span>
                    <div className='icon-ser-pg'>
                        <img src='static/media/images/webicon.png'/>
                    </div>
                    <h4>Internet of Things (IoT) Solutions</h4>
                    <p>Developing software solutions that integrate with IoT devices to collect, analyze, and act on data from connected devices.</p>
                    {/* <div className="read-more-bttn">
                      <a href="/services-details">Read More</a>
                    </div> */}
                </div>
            </div>

            <div className='col-service-pg'>
                <div className='col-service-pg-inner'>
                    <span>07</span>
                    <div className='icon-ser-pg'>
                        <img src='static/media/images/softwaredev.png'/>
                    </div>
                    <h4>Blockchain Development</h4>
                    <p>Building decentralized applications (dApps), smart contracts, and blockchain solutions for various industries.</p>
                    {/* <div className="read-more-bttn">
                      <a href="/services-details">Read More</a>
                    </div> */}
                </div>
            </div>

            <div className='col-service-pg'>
                <div className='col-service-pg-inner'>
                    <span>08</span>
                    <div className='icon-ser-pg'>
                        <img src='static/media/images/webicon.png'/>
                    </div>
                    <h4>AI and Machine Learning Services</h4>
                    <p>Integrating artificial intelligence and machine learning algorithms into software applications to enable automation, predictive analytics, and personalized experiences.</p>
                    {/* <div className="read-more-bttn">
                      <a href="/services-details">Read More</a>
                    </div> */}
                </div>
            </div>

            <div className='col-service-pg'>
                <div className='col-service-pg-inner'>
                    <span>09</span>
                    <div className='icon-ser-pg'>
                        <img src='static/media/images/video.png'/>
                    </div>
                    <h4>Augmented Reality (AR) and Virtual Reality (VR) Solutions</h4>
                    <p>Creating immersive experiences and applications using AR and VR technologies for gaming, training, marketing, and more.</p>
                    {/* <div className="read-more-bttn">
                      <a href="/services-details">Read More</a>
                    </div> */}
                </div>
            </div>

            <div className='col-service-pg'>
                <div className='col-service-pg-inner'>
                    <span>10</span>
                    <div className='icon-ser-pg'>
                        <img src='static/media/images/graphic-ico.png'/>
                    </div>
                    <h4>Quality Assurance and Testing</h4>
                    <p>Ensuring the reliability, functionality, and performance of software through rigorous testing processes.</p>
                    {/* <div className="read-more-bttn">
                      <a href="/services-details">Read More</a>
                    </div> */}
                </div>
            </div>

            <div className='col-service-pg'>
                <div className='col-service-pg-inner'>
                    <span>11</span>
                    <div className='icon-ser-pg'>
                        <img src='static/media/images/ui.png'/>
                    </div>
                    <h4>Maintenance and Support</h4>
                    <p>Providing ongoing maintenance, updates, and technical support for software applications post-launch.</p>
                    {/* <div className="read-more-bttn">
                      <a href="/services-details">Read More</a>
                    </div> */}
                </div>
            </div>
          </div>
            </div>
        </div>
        <Footer1/>
    </div>
  )
}

export default ServicesPg