import React from 'react';
import Navbar1 from './Components/Navbar1';
import Home from './Pages/Home';
import About from './Pages/AboutPg';
import ServicesPg from './Pages/ServicesPg';
import ProjectPg from './Pages/ProjectPg';
import ContactPg from './Pages/ContactPg';
import ProjectDetails from './Pages/ProjectDetails';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Routes, Route} from 'react-router-dom';
function App() {
  return (
    <div className="App">
      {/* <Navbar1/> */}
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/services' element={<ServicesPg/>}/>
      <Route path='/project' element={<ProjectPg/>}/>
      <Route path='/contact' element={<ContactPg/>}/>
      <Route path='/project-details' element={<ProjectDetails/>}/>
    </Routes>
      {/* <Home/> */}
    </div>
  );
}

export default App;
