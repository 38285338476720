import React from 'react'
import './Footer1.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';

function Footer1() {
  return (
    <div>
        <div className='footer-main'>
            <div className='footer-container'>
                <div className='footer-top'>
                    <div className='footer-row'>
                        <div className='col-footer'>
                            <div className='footer-widget'>
                                <div className='footer-logo'>
                                    <a  href='/'>
                                        <img src='static/media/images/logo1bgrev.png' className='footer-logo-img'/>
                                    </a>
                                </div>
                                {/* <br/> */}
                                <p>Empowering Your Digital Evolution. Innovate, Elevate, Excel with Tech Logix.</p>
                            </div>
                        </div>

                        <div className='col-footer'>
                            <div className='footer-widget' style={{width:'300px'}}>
                                <h4>Our Services</h4>
                                <ul className='footer-menu'>
                                    <li>
                                        <a href='/services' className='footer-link'>
                                        Enterprise Software Solutions
                                        </a>
                                    </li>
                                    <li>
                                        <a href='/services' className='footer-link'>
                                        Data Analytics and Business Intelligence
                                        </a>
                                    </li>
                                    <li>
                                        <a href='/services' className='footer-link'>
                                        Cybersecurity Services
                                        </a>
                                    </li>
                                    <li>
                                        <a href='/services' className='footer-link'>
                                        Consulting Services
                                        </a>
                                    </li>
                                    <li>
                                        <a href='/services' className='footer-link'>
                                        Cloud Solutions
                                        </a>
                                    </li><li>
                                        <a href='/services' className='footer-link'>
                                        Internet of Things (IoT) Solutions
                                        </a>
                                    </li><li>
                                        <a href='/services' className='footer-link'>
                                        Blockchain Development
                                        </a>
                                    </li><li>
                                        <a href='/services' className='footer-link'>
                                        AI and Machine Learning Services
                                        </a>
                                    </li><li>
                                        <a href='/services' className='footer-link'>
                                        Augmented Reality (AR) and Virtual Reality (VR) Solutions
                                        </a>
                                    </li><li>
                                        <a href='/services' className='footer-link'>
                                        Quality Assurance and Testing
                                        </a>
                                    </li><li>
                                        <a href='/services' className='footer-link'>
                                        Maintenance and Support
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className='col-footer'>
                            <div className='footer-widget'>
                                <h4>Quick Links</h4>
                                <ul className='footer-menu'>
                                    <li>
                                        <a href='/about' className='footer-link'>
                                            About Us
                                        </a>
                                    </li>
                                    <li>
                                        <a href='/services' className='footer-link'>
                                            Services
                                        </a>
                                    </li>
                                    <li>
                                        <a href='/project' className='footer-link'>
                                            Projects
                                        </a>
                                    </li>
                                    <li>
                                        <a href='/contact' className='footer-link'>
                                            Contact Us
                                        </a>
                                    </li>
                                  
                                </ul>
                            </div>
                        </div>

                        <div className='col-footer'>
                            <div className='footer-widget'>
                                <h4>Contacts</h4>
                                {/* <div className='number'>
                                    <div className='num-icon'>
                                        <FontAwesomeIcon icon={faPhone} className='icon-footer-contact'/>
                                    </div>
                                    <div className='phone'>
                                        <a style={{color:'#d5d5d5'}}>+123 456 789</a>
                                        <a style={{color:'#d5d5d5'}}>+92 123 456 789</a>
                                    </div>
                                </div> */}

                                <div className='number'>
                                    <div className='num-icon'>
                                        <FontAwesomeIcon icon={faEnvelope} className='icon-footer-contact'/>
                                    </div>
                                    <div className='phone'>
                                        <a style={{color:'#d5d5d5'}}>info@techlogix.au</a>
                                        {/* <a style={{color:'#d5d5d5'}}>info@company.com</a> */}
                                    </div>
                                </div>

                                <div className='number'>
                                    <div className='num-icon'>
                                        <FontAwesomeIcon icon={faLocationDot} className='icon-footer-contact'/>
                                    </div>
                                    <div className='phone'>
                                        <a style={{color:'#d5d5d5'}}>Oxbow street 
Micklenham 3064
Victoria, Australia</a>
                                        {/* <a href='/'>info@company.com</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='footer-bottom'>
                    <div className='footer-bottom-row'>
                        <p>Copyrights © 2024. All Rights Reserved by <a href='/'>Tech Logix</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer1