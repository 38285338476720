import React from 'react';
import Navbar1 from '../Components/Navbar1';
import './AboutPg.css';
import Footer1 from '../Components/Footer1';
import About from '../Components/About';
import Features from '../Components/Features';
function AboutPg() {
  return (
    <div>
        <Navbar1/>
        <div className='aboutpg-main'>
            <div className='pg-container'>
                <div className='pg-row'>
                    <div className='pg-col'>
                        <div className='pg-wrapper'>
                            <div className='pg-wrapper-cnt'>
                                <h1>About Us</h1>

                                <div className='about-img-wrapper'>
                                    <img src='static/media/images/about-pg-img2.jpg'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <About/>
        <div className='about-mob' style={{marginTop:'-20%'}}>
        <div className='col1-about' style={{width:'100%', textAlign:'justify', lineHeight:'1'}}>
                <span>CEO Message</span>
                <p>Dear Valued Partners and Clients, <br/>

I am thrilled to welcome you to our dynamic software house, where innovation and excellence converge. At Tech Logix, our mission is to empower businesses like yours with cutting-edge technology solutions that drive success and transformation. 
<br/>
We understand that in today's fast-paced digital landscape, staying ahead requires more than just great software – it demands a relentless commitment to quality, reliability, and customer satisfaction. That's why our team is dedicated to delivering nothing short of excellence in everything we do. 
<br/>
From crafting bespoke Enterprise Software Solutions to providing meticulous Quality Assurance and Testing, and offering ongoing Maintenance and Support, we are here every step of the way to ensure your success. Furthermore, our expertise in Data Analytics and Business Intelligence empowers you to make data-driven decisions that propel your business forward. 
<br/>
As we continue this journey together, I want to express my deepest gratitude for your trust and partnership. Together, let's redefine possibilities and shape the future of technology. 

 <br/>

Warm regards, 
<br/>
<b>Syed Jawad Hussain</b> 
<br/>
<b>CEO, Tech Logix</b> </p>
            </div>
        </div>
        <div className='about-section'>
            <div className='col1-about' style={{width:'100%', paddingRight:'100px', textAlign:'justify'}}>
                <span>CEO Message</span>
                <p>Dear Valued Partners and Clients, <br/>

I am thrilled to welcome you to our dynamic software house, where innovation and excellence converge. At Tech Logix, our mission is to empower businesses like yours with cutting-edge technology solutions that drive success and transformation. 
<br/>
We understand that in today's fast-paced digital landscape, staying ahead requires more than just great software – it demands a relentless commitment to quality, reliability, and customer satisfaction. That's why our team is dedicated to delivering nothing short of excellence in everything we do. 
<br/>
From crafting bespoke Enterprise Software Solutions to providing meticulous Quality Assurance and Testing, and offering ongoing Maintenance and Support, we are here every step of the way to ensure your success. Furthermore, our expertise in Data Analytics and Business Intelligence empowers you to make data-driven decisions that propel your business forward. 
<br/>
As we continue this journey together, I want to express my deepest gratitude for your trust and partnership. Together, let's redefine possibilities and shape the future of technology. 

 <br/>

Warm regards, 
<br/>
<b>Syed Jawad Hussain</b> 
<br/>
<b>CEO, Tech Logix</b> </p>

            </div>
        </div>
        <Features/>
        <Footer1/>
    </div>
  )
}

export default AboutPg