import React from "react";
import "./Home.css";
import Navbar1 from "../Components/Navbar1";
import Services from "../Components/Services";
import Features from "../Components/Features";
import About from "../Components/About";
import Footer1 from "../Components/Footer1";
import Project from "../Components/Project";

function Home() {
  return (
    <div className="home-wrap">
      <div className="line-wrap">
        <div className="line-item"></div>
        <div className="line-item"></div>
        <div className="line-item"></div>
        <div className="line-item"></div>
        <div className="line-item"></div>
      </div>
      <section className="hero-section">
        <Navbar1 />
        <div className="slideshow">
          <img src="static/media/images/slideimg.jpg" className="slide-img" />
        </div>


        <div className="mob-wrapper">
          <div className="mob-container">
            <div className="row-mob">
              <div className="col-mob">
                <div className="hero-content">
                  <h1>
                    Creative & Minimal
                    <span> IT Agency.</span>
                  </h1>
                  <div className="buttons-hero">
              <div className="cmn-bttn">
                <div className="line1"></div>
                <div className="line2"></div>
                <a href="/about" className="quote-link">
                  About Us
                </a>
              </div>
              <div className="cmn-bttn-2">
                <div className="line1"></div>
                <div className="line2"></div>
                <a href="/project" className="layout2">
                  See Projects
                </a>
              </div>
            </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="vertical-social">
          <ul className="vertical-media">
            <li>
              <a href="/" className="media-links">
                Facebook
              </a>
            </li>
            <li>
              <a href="/" className="media-links">
                Instagram
              </a>
            </li>
            <li>
              <a href="/" className="media-links">
                Linkedin
              </a>
            </li>
          </ul>
        </div>
        {/* <div className='hero-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <div className='hero-content'>
                                <h1>Creative & Minimal
                                    <span> IT Agency.</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

       

        <div className="wrapper">
          <div className="hero-content">
            <h1>
              Creative & Minimal
              <span> IT Agency.</span>
            </h1>

            <div className="buttons-hero">
              <div className="cmn-bttn">
                <div className="line1"></div>
                <div className="line2"></div>
                <a href="/about" className="quote-link">
                  About Us
                </a>
              </div>
              <div className="cmn-bttn-2">
                <div className="line1"></div>
                <div className="line2"></div>
                <a href="/project" className="layout2">
                  See Projects
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>


      <Services/>
      <About/>
      <Features/>
      <Project/>
    <Footer1/>
    </div>
  );
}

export default Home;
