
import React, { useState, useRef } from "react";
import "./Services.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";

function Services() {
  
  const [currentSlide, setCurrentSlide] = useState(0);
  const scrollContainerRef = useRef(null);
  const cards = [
    {
      id: 1,
      title: "Enterprise Software Solutions",
      description: "Building scalable and robust software solutions for enterprise-level organizations to streamline operations and improve efficiency.",
      imagePath: "static/media/images/webicon.png",
    },
    {
      id: 2,
      title: "Data Analytics and Business Intelligence",
      description: "Leveraging data to provide insights, analytics, and reporting tools to support informed decision-making. ",
      imagePath: "static/media/images/ui.png",
    },
    {
      id: 3,
      title: "Cybersecurity Services",
      description: "Assessing, implementing, and managing cybersecurity measures to protect software and data from cyber threats.",
      imagePath: "static/media/images/webicon.png",
    },
    {
      id: 4,
      title: "Consulting Services",
      description: "Offering expert advice and consultancy on technology strategy, digital transformation, and software architecture.",
      imagePath: "static/media/images/ui.png",
    },{
      id:5,
      title:"Cloud Solutions",
      description:"Development and implementation of cloud-based solutions, including migration, deployment, and optimization.",
      imagePath:"static/media/images/softwaredev.png",
    },{
      id:6,
      title:"Internet of Things (IoT) Solutions",
      description:"Developing software solutions that integrate with IoT devices to collect, analyze, and act on data from connected devices.",
      imagePath:"static/media/images/webicon.png",
    },{
      id:7,
      title:"Blockchain Development",
      description:"Building decentralized applications (dApps), smart contracts, and blockchain solutions for various industries.",
      imagePath:"static/media/images/softwaredev.png",
    },{
      id:8,
      title:"AI and Machine Learning Services",
      description:"Integrating artificial intelligence and machine learning algorithms into software applications to enable automation, predictive analytics, and personalized experiences.",
      imagePath:"static/media/images/webicon.png",
    },{
      id:9,
      title:"Augmented Reality (AR) and Virtual Reality (VR) Solutions",
      description:"Creating immersive experiences and applications using AR and VR technologies for gaming, training, marketing, and more.",
      imagePath:"static/media/images/video.png",
    },{
      id:10,
      title:"Quality Assurance and Testing",
      description:"Ensuring the reliability, functionality, and performance of software through rigorous testing processes.",
      imagePath:"static/media/images/graphic-ico.png",
    },{
      id:11,
      title:"Maintenance and Support",
      description:"Providing ongoing maintenance, updates, and technical support for software applications post-launch.",
      imagePath:"static/media/images/ui.png",
    }
  ];

  const handleNextClick = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 300; // Adjust the scroll distance as needed
    }
    setCurrentSlide((prevSlide) => (prevSlide + 1) % cards.length);
  };

  const handlePrevClick = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 300; // Adjust the scroll distance as needed
    }
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? cards.length - 3 : prevSlide - 1
    );
  };

  return (
    <div>
      <section className="services-sec-main">
        <div className="services-container">
          <div className="title-services">
            <div className="sec-title">
              <span className="span-service">Our Solutions</span>
              <h2 className="h2-service">Services</h2>
              <p>
              "Empowering Your Digital Evolution.
Innovate, Elevate, Excel with Tech Logix."
              </p>
            </div>
          </div>
        </div>

        <div className="swipper-container">
          <div className="swipper-prev-bttn" onClick={handlePrevClick}>
            <FontAwesomeIcon icon={faBackward} className="icon-service" />
          </div>
          <div className="swipper-next-bttn" onClick={handleNextClick}>
            <FontAwesomeIcon icon={faForward} className="icon-service" />
          </div>
          <div className="swipper-viewport">
            <div className="swipper-cards card-container" ref={scrollContainerRef}>
              {cards.map((card, index) => (
                <div
                  key={card.id}
                  className={`card ${
                    index >= currentSlide && index < currentSlide + 3 ? "active" : ""
                  }`}
                >
                  <div className="single-service">
                    <span>{card.id}</span>
                    <div className="icon-card">
                      <img
                        src={card.imagePath}
                        alt={card.title}
                        className="icon-cards"
                      
                      />
                    </div>
                    <h4>{card.title}</h4>
                    <p className="p-service-des">{card.description}</p>
                    <div className="read-more-bttn">
                      <a href="/services">Read More</a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;
