import React from 'react'
import Navbar1 from '../Components/Navbar1';
import Footer1 from '../Components/Footer1';
import Project from '../Components/Project';
function ProjectPg() {
  return (
    <div>
        <Navbar1/>
        <div className='aboutpg-main'>
            <div className='pg-container'>
                <div className='pg-row'>
                    <div className='pg-col'>
                        <div className='pg-wrapper'>
                            <div className='pg-wrapper-cnt'>
                                <h1>Projects</h1>

                                <div className='about-img-wrapper'>
                                    <img src='static/media/images/about-pg-img2.jpg'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    <Project/>
        <Footer1/>
    </div>
  )
}

export default ProjectPg