import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import './Navbar1.css';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

function Navbar1() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  return (
    <div>

{/* Mobile and Tablet */}
<div className='mob-nav'>

<Navbar className='nav-header'>
        <Container className='container'>
            <div className='logo-main'>
          <Navbar.Brand href="/" className='logo-main'>
            <img src='static/media/images/logo1bgrev.png' alt='Tech Logix' className='real-logo'/>
          </Navbar.Brand>
          </div>

          <div className='nav-right'>
          <Button variant='outline-dark' onClick={handleShow} className='mob-nav-btn'>
        <FontAwesomeIcon icon={faBars} className='icon-mob'/>
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton className='title-mob'>
          <Offcanvas.Title className='title-offcanvas'>
            <img src='static/media/images/logo-white.png' alt='Tech Logix' className='real-logo2' />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <div className='mob-nav-body'>
            <Nav.Link href="/" id='nav'>Home</Nav.Link>
            <Nav.Link href="/about" id='nav'>About us</Nav.Link>
            <Nav.Link href="/services" id='nav'>Services</Nav.Link>
            <Nav.Link href='/project' id='nav'>Projects</Nav.Link>
            <Nav.Link href="/contact" id='nav'>Contact</Nav.Link>

            </div>
            <div className='nav-right'>
            <div className='get-quote'>
                <div className='cmn-bttn'>
                    <div className='line1'></div>
                    <div className='line2'></div>
                    <a href='/contact' className='quote-link'>Get A Quote</a>
                </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>


          </div>
          </Container>

          </Navbar>



      </div>

<div className='large-screen'>
<Navbar className='nav-header'>
        <Container className='container'>
            <div className='logo-main'>
          <Navbar.Brand href="/" className='logo-main'>
            <img src='static/media/images/logo1bgrev.png' alt='Tech Logix' className='real-logo'/>
          </Navbar.Brand>
          </div>
          <div className='main-nav'>
          <Nav className="mx-auto"  >
            <Nav.Link href="/" id='nav'>Home</Nav.Link>
            <Nav.Link href="/about" id='nav'>About us</Nav.Link>
            <Nav.Link href="/services" id='nav'>Services</Nav.Link>
            <Nav.Link href='/project' id='nav'>Projects</Nav.Link>
            <Nav.Link href="/contact" id='nav'>Contact</Nav.Link>

          </Nav>
          </div>

          <div className='nav-right'>
            <div className='get-quote'>
                <div className='cmn-bttn'>
                    <div className='line1'></div>
                    <div className='line2'></div>
                    <a href='/contact' className='quote-link'>Get A Quote</a>
                </div>
            </div>
          </div>
        </Container>
      </Navbar>
      </div>
    </div>
  )
}

export default Navbar1