import React from 'react'
import './Features.css'

function Features() {
  return (
    <div>
        <div className='features-main'>
            <div className='feature-container'>
                <div className='title-feature'>
                    <div className='inner-title-feature'>
                        <span>Care Study</span>
                        <h2>Features</h2>
                        <p>Innovative software solutions with a focus on excellence, from tailored development to ongoing support. Transforming data into actionable insights for unparalleled success.</p>
                    </div>
                </div>

                <div className='feature-row'>
                    <div className='feature-col1'>
                        <div className='single-feature'>
                            <div className='feature-inner'>
                                <div className='icon-feature'>
                                    <img src='static/media/images/feature-icon-1.png'/>
                                </div>
                                <span>05</span>
                                <sup>+</sup>
                                <h4>Project Completed</h4>
                            </div>
                        </div>
                    </div>

                    <div className='feature-col1'>
                        <div className='single-feature'>
                            <div className='feature-inner'>
                                <div className='icon-feature'>
                                    <img src='static/media/images/feature-icon-2.png'/>
                                </div>
                                <span>25</span>
                                <sup>+</sup>
                                <h4>Satisfied Clients</h4>
                            </div>
                        </div>
                    </div>

                    <div className='feature-col1'>
                        <div className='single-feature'>
                            <div className='feature-inner'>
                                <div className='icon-feature'>
                                    <img src='static/media/images/feature-icon-3.png'/>
                                </div>
                                <span>50</span>
                                <sup>+</sup>
                                <h4>Expert Teams</h4>
                            </div>
                        </div>
                    </div>

                    <div className='feature-col1'>
                        <div className='single-feature'>
                            <div className='feature-inner'>
                                <div className='icon-feature'>
                                    <img src='static/media/images/feature-icon-4.png'/>
                                </div>
                                <span>10</span>
                                <sup>+</sup>
                                <h4>Win Awards</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  )
}

export default Features